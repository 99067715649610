<template>
    <Head :title="computedTitle">
        <slot />

        <title>{{ computedTitle }}</title>
        <meta v-if="description" name="description" :content="description" />
        <meta v-if="keywords" name="keywords" :content="keywords" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta v-if="computedTitle" property="og:title" :content="computedTitle" />
        <meta v-if="description" property="og:description" :content="description" />
        <meta v-if="image.url" property="og:image" :content="image.url" />
        <meta v-if="image.type" property="og:image:type" :content="image.type" />
        <meta v-if="image.width" property="og:image:width" :content="image.width" />
        <meta v-if="image.height" property="og:image:height" :content="image.height" />
        <meta v-if="image.alt" property="og:image:alt" :content="image.alt" />
        <meta property="og:site_name" content="vidfaceswap" />
        <meta v-if="url" property="og:url" :content="url" />
        <link v-if="canonical" rel="canonical" :href="canonical" />
        <meta v-if="computedTitle" name="twitter:card" content="summary" />
        <meta v-if="computedTitle" name="twitter:title" :content="computedTitle" />
        <meta v-if="description" name="twitter:description" :content="description" />
        <meta v-if="image.url" name="twitter:image" :content="image.url" />
        <meta v-if="image.alt" name="twitter:image:alt" :content="image.alt" />
    </Head>
</template>

<script setup>
import { Head } from '@inertiajs/vue3'
import { computed } from 'vue'

const props = defineProps({
    title: {
        required: false,
        type: String,
    },
    description: {
        required: false,
        type: String,
    },
    image: {
        required: false,
        type: Object,
        default() {
            return {
                url: null,
                type: null,
                width: null,
                height: null,
                alt: null,
            }
        },
    },
    url: {
        required: false,
        type: String,
    },
    canonical: {
        required: false,
        type: String,
    },
    keywords: {
        required: false,
        type: String,
    },
    appendAppName: {
        required: false,
        type: Boolean,
        default: true,
    },
})

const computedTitle = computed(() => {
    const appName = 'vidfaceswap'

    if (!props.title) {
        return appName
    }

    if (!props.appendAppName) {
        return props.title
    }

    return `${props.title} - ${appName}`
})
</script>
